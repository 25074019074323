import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import Page from './Page'
import { createContext, useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import ordersApi from '../api/orders'
import { parseError } from '../utils'
import useToast from '../hooks/useToast'
import ROUTES from '../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCustomers,
  fetchEcommerce,
  fetchPartners
} from '../slices/customersSlice'
import ConfirmSaveOrder from '../components/orders/drafts/ConfirmSaveOrder'
import customerApi from '../api/customer'
import { Loader } from '../components/globals'

export const CreateOrderContext = createContext()

export default function CreateOrderLayout () {
  const userData = useSelector(state => state.auth.user)

  const { orderId } = useParams()
  const [searchParams] = useSearchParams()
  const multipiece = searchParams.get('mpo')
  const bill_to = searchParams.get('bill_to')
  const bill_to_id = searchParams.get('bill_to_id')
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [activeStep, updateActiveStep] = useState({})

  const [order, setOrder] = useState({
    payload: {
      sender: null,
      receiver: null,
      partner: false,
      shipa_or_ecommerce: false,
      type: '',
      package_insurance: '',
      packages: null,
      tpl_service: '',
      description: '',
      served_by: `${userData?.employee.first_name} ${userData?.employee.last_name}`,
      branch_name: `${userData?.branch.name}`,
      draft: true,
      category: null,
      get_acknowledgement_copy: false,
      is_sales_force: false
    },
    meta: {
      order_id: orderId || null
    }
  })

  const [isSaving, setSaving] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isSaveModal, setSaveModal] = useState(false)

  const updateOrder = useCallback(
    payload => {
      const newOrder = {
        ...order,
        payload: {
          ...order.payload,
          ...payload
        }
      }

      setOrder(newOrder)
    },
    [order]
  )

  const formatCustomer = useCallback((customer, type) => {
    if (type === 'fromOrder') {
      const {
        address,
        contact,
        customer_id,
        partner,
        pay_later_eligible,
        shipa_or_ecommerce
      } = customer
      return {
        address,
        contact,
        customer_id,
        partner: !!partner,
        pay_later_eligible: !!pay_later_eligible,
        shipa_or_ecommerce: !!shipa_or_ecommerce
      }
    } else {
      const street_lines = [customer.address]
      customer.alt_address && street_lines.push(customer.alt_address)
      customer.alt_address_2 && street_lines.push(customer.alt_address_2)
      const formattedCustomer = {
        address: {
          street_lines,
          country: customer.country,
          state_or_province_code: customer.state,
          state_name: customer.state_name,
          city: customer.city,
          postal_code: customer.postcode,
          country_code: customer.code
        },
        contact: {
          name: customer.full_name,
          email_address: customer.email,
          phone_number: customer.phone_number,
          business_name: customer.business_name,
          business_contact: customer.business_contact
        },
        customer_id: customer.id,
        partner: customer.partner,
        pay_later_eligible: customer.pay_later_eligible
      }
      if (customer.area_or_province) {
        formattedCustomer.address.area_or_province = customer.area_or_province
      }

      return formattedCustomer
    }
  }, [])

  const fetchAndSetOrder = async orderId => {
    const response = await ordersApi.readOrder(orderId)
    if (response.ok) {
      const { order } = response.data.payload

      const { type, items, addOns, packages, itemsValue, exportReason } =
        order.packages

      const _packages = {
        type,
        items,
        addOns,
        packages,
        itemsValue,
        exportReason
      }

      const payload = {
        sender: formatCustomer(order.sender, 'fromOrder'),
        receiver: formatCustomer(order.receiver, 'fromOrder'),
        partner: order.partner,
        shipa_or_ecommerce: !!order.shipa_or_ecommerce,
        type: order.type,
        package_insurance: order.package_insurance,
        packages: _packages,
        tpl_service: order.tpl_service,
        description: order.description,
        served_by: order.served_by,
        branch_name: order.branch_name,
        draft: !!order.draft
      }

      if (payload.type === 'LC') {
        payload.category = order.category
        payload.delivery_mode = order.delivery_mode
        payload.get_acknowledgement_copy = order.get_acknowledgement_copy
        payload.is_sales_force = order.is_sales_force

        if (payload.delivery_mode === 'PICKUP') {
          payload.pickup_hub = order.pickup_hub
        }

        if (payload.is_sales_force) {
          payload.salesforce_code = order.salesforce_code || ''
        }
      } else {
        payload.delivery_type = order.delivery_type
      }

      if (payload.package_insurance === 'PM') {
        payload.insured_value = order.insured_value
      }

      setOrder({ payload, meta: { order_id: order.id } })
      isLoading && setLoading(false)
    }
  }

  const steps = (() => {
    return Array.from({ length: 8 }).map((_, idx) => idx + 1)
  })()

  useEffect(() => {
    dispatch(fetchCustomers())
    dispatch(fetchPartners())
    dispatch(fetchEcommerce())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state?.payload) {
      setOrder(state => {
        const payload = Object.assign({}, state.payload, location.state.payload)

        return {
          ...state,
          payload
        }
      })
    }
  }, [location])

  useEffect(() => {
    if (orderId) {
      fetchAndSetOrder(orderId)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  const fetchAndSetBillToCustomer = async () => {
    const billToCustomer = await customerApi.readCustomer(bill_to_id)
    updateOrder({
      [bill_to]: formatCustomer(billToCustomer.data, 'new')
    })
  }

  useEffect(() => {
    if (multipiece && bill_to && bill_to_id) {
      fetchAndSetBillToCustomer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill_to, bill_to_id, multipiece])

  useEffect(() => {
    if (order.payload?.sender && order.payload.receiver) {
      updateOrder({
        partner: order.payload.sender.partner || order.payload.receiver.partner
      })

      updateOrder({
        shipa_or_ecommerce:
          order.payload.sender.shipa_or_ecommerce ||
          order.payload.receiver.shipa_or_ecommerce
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.payload?.receiver, order.payload?.sender])

  const handleSaveAndExit = async () => {
    if (activeStep.stepId < 6) {
      setSaveModal(true)
    } else navigate(ROUTES.ORDERS.path)
  }

  const handleSave = async () => {
    let response

    const items = order.payload.packages.items.map(item => {
      const formattedItem = Object.keys(item).reduce((acc, key) => {
        // eslint-disable-next-line default-case
        switch (key) {
          case 'quantity':
            acc.quantity = acc.quantity === '' ? 1 : acc.quantity
            break
          case 'price':
            acc.price = acc.price === '' ? 0.00001 : acc.price
            break
          case 'hsCode':
            acc.hsCode = acc.hsCode === '' ? 0.00001 : acc.hsCode
            break
          case 'weight':
            acc.weight = acc.weight === '' ? 0.00001 : acc.weight
            break
        }

        return acc
      }, item)

      return formattedItem
    })

    const packages = order.payload.packages.packages.map(_package => {
      const formattedPackage = Object.keys(_package).reduce((acc, key) => {
        // eslint-disable-next-line default-case
        switch (key) {
          case 'actualWeight':
            acc.actualWeight =
              _package.actualWeight === '' ? 0 : _package.actualWeight
        }

        return acc
      }, _package)

      return formattedPackage
    })

    const payload = { ...order.payload }
    payload.packages.items = items
    payload.packages.packages = packages
    payload.packages.itemsValue = payload.packages.itemsValue
      ? payload.packages.itemsValue
      : 0

    if (order.meta.order_id) {
      response = await ordersApi.updateOrder(order.meta.order_id, payload)
    } else {
      response = await ordersApi.createOrder(payload)
    }

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error saving draft', 'error')
      }
      return false
    }

    toast('Saved order as draft successfully', 'success')

    return true
  }

  const onConfirmSave = async () => {
    if (!isSaving) {
      setSaving(true)
      const isSaveSuccess = await handleSave()
      setSaving(false)
      if (isSaveSuccess) {
        navigate(ROUTES.ORDERS.DRAFTS.path)
      }
    }
  }

  const onDeclineSave = () => {
    window.sessionStorage.removeItem('order')
    navigate(ROUTES.ORDERS.path)
  }

  const onCloseConfirmSave = () => {
    if (!isSaving) {
      setSaveModal(false)
    }
  }

  const resolvePathname = path => {
    return orderId
      ? `${ROUTES.ORDERS.CREATE_ORDER.path}${orderId}/${path}`
      : `${ROUTES.ORDERS.CREATE_ORDER.path}${path}`
  }

  return (
    <CreateOrderContext.Provider
      value={{
        updateActiveStep,
        order,
        updateOrder,
        resolvePathname,
        formatCustomer
      }}
    >
      <Page metaTitle={activeStep.metaTitle}>
        <Page.Header
          title='Create New Order'
          backRoute={activeStep.back}
          onBack={activeStep.onBack}
        />
        <Page.Body>
          <ConfirmSaveOrder
            isOpen={isSaveModal}
            onConfirm={onConfirmSave}
            onDecline={onDeclineSave}
            onClose={onCloseConfirmSave}
            type={orderId ? 'draft' : 'new'}
            isSaving={isSaving}
          />
          <div className='my-2 lg:my-8 w-full flex flex-col gap-3 md:block md:relative'>
            {activeStep.stepId >= 4 && (
              <button
                className='btn btn-sm btn-primary self-end md:absolute md:right-0 md:bottom-0'
                onClick={handleSaveAndExit}
              >
                Save/Exit
              </button>
            )}
            <ul className='steps'>
              {steps.map((_, idx) => {
                return (
                  <li
                    key={`step-${idx}`}
                    className={twMerge(
                      'step',
                      activeStep.stepId === idx && 'step-primary'
                    )}
                  ></li>
                )
              })}
            </ul>
          </div>
          {isLoading ? <Loader /> : <Outlet />}
        </Page.Body>
      </Page>
    </CreateOrderContext.Provider>
  )
}
