import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import useToast from '../../hooks/useToast'
import { LiaTimesSolid } from 'react-icons/lia'
import manifestApi from '../../api/manifest'
import { fetchExceptions } from '../../slices/exceptionsSlice'
import { CircularProgress } from '@mui/material'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/react'
import { HiChevronDown } from 'react-icons/hi2'
import { twMerge } from 'tailwind-merge'
import { MdCheck } from 'react-icons/md'

const customStyles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function ShipmentException ({ isOpen, onClose, shipmentId }) {
  const dispatch = useDispatch()
  const data = useSelector(state => state?.exceptions?.dataExceptions)
  const toast = useToast()
  const [exception, setException] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchExceptions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disabled = useMemo(() => !exception, [exception])

  const handleException = async () => {
    setLoading(true)
    let result = null

    const payload = {
      scan_type: 'ORIGIN_SCAN',
      object_type: 'SHIPMENT',
      shipment_id: shipmentId,
      exception: exception.code
      // meta: {
      //   location: location,
      //   hub: hub
      // }
    }

    result = await manifestApi.sendShipmentException(payload)

    if (result.ok) {
      toast('Exception raised!', 'success')
      setLoading(false)
    }

    if (!result.ok) {
      setLoading(false)
      toast(result?.data?.error, 'error')
    }
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        appElement={document.getElementById('root')}
      >
        <div className='pb-9'>
          <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
          <h1 className='w-full flex justify-center items-center text-2xl font-semibold mb-3'>
            Raise Exception
          </h1>

          <div className='space-y-4'>
            <div className='w-full bg-g-200 px-8 py-3 space-y-4'>
              <div>
                <h3 className='font-medium text-lg'>Shipment details</h3>
              </div>
              <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                <div className='space-y-2'>
                  <p className='text-base'>Shipment ID</p>
                  <div className='flex justify-start items-center px-3 bg-white w-full border-[#CBCBCB] border-[1px] h-[40px] rounded-lg text-[15px]'>
                    {shipmentId?.toUpperCase()}
                  </div>
                </div>
              </div>
            </div>

            <div className='w-full bg-g-200 px-8 py-3'>
              <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                <div className='space-y-2'>
                  <p className='font-medium text-base'>Reason of Exception</p>
                  <ReasonForExceptionInput
                    exception={exception}
                    onChange={setException}
                    reasons={data}
                  />
                </div>
                <div className='space-y-2'>
                  <p className='font-medium text-base'>Exception code</p>
                  <div className='flex justify-start items-center px-3 bg-white w-full border-[#CBCBCB] border-[1px] h-[40px] rounded-lg text-[15px]'>
                    {exception?.code}
                  </div>
                </div>
              </div>

              <div className='w-full flex justify-end mt-12'>
                <button
                  className={`btn min-w-32 btn-rounded ${
                    disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
                  }`}
                  disabled={disabled}
                  onClick={handleException}
                >
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const ReasonForExceptionInput = ({ reasons, exception, onChange }) => {
  const [query, setQuery] = useState('')

  const filteredReasons = useMemo(() => {
    return query === ''
      ? reasons
      : reasons?.filter(reason => {
          return reason.message.toLowerCase().includes(query.toLowerCase())
        })
  }, [query, reasons])

  return (
    <Combobox
      value={exception}
      onChange={onChange}
      onClose={() => setQuery('')}
      as='div'
      className='relative'
    >
      <div className='relative bg-white border border-[#CBCBCB] rounded-lg h-10 pl-3 pr-10 overflow-hidden w-full'>
        <ComboboxInput
          aria-label='Exception'
          displayValue={reason => reason?.message}
          onChange={({ target }) => setQuery(target.value)}
          className='w-full h-full text-[15px] px-0 border-none outline-0'
        />
        <ComboboxButton className='group absolute inset-y-0 right-0 px-2.5 hover:bg-main-hover hover:text-main-primary'>
          <HiChevronDown size={14} />
        </ComboboxButton>
      </div>

      <ComboboxOptions
        transition
        className={twMerge(
          'absolute top-full mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black/5 p-1 empty:invisible max-h-40 overflow-y-auto',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
        as='ul'
      >
        {filteredReasons?.map(reason => (
          <ComboboxOption
            as='li'
            key={reason.id}
            value={reason}
            className='data-[focus]:bg-main-hover cursor-pointer data-[focus]:text-main-primary-2 py-1.5 px-2 text-sm'
          >
            {({ selected }) => (
              <div className='flex w-full items-center justify-between gap-1'>
                <div>{reason.message}</div>
                {selected && (
                  <span className='pointer-events-none ml-auto flex items-center'>
                    <MdCheck size={14} color='#fe6802' />
                  </span>
                )}
              </div>
            )}
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  )
}
