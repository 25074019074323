import opay from '../../assets/opay-bank.png'
import cash from '../../assets/icons/cash.png'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  confirmCashPayment,
  initiateTransaction,
  transactionActions
} from '../../slices/transactionSlice'
import { CircularProgress } from '@mui/material'
import useToast from '../../hooks/useToast'
import { paymentSocketActions } from '../../slices/paymentSocketSlice'
import PaymentSuccessful from './PaymentSuccessful'
import { useLocation } from 'react-router-dom'
import { Loader } from '../globals'
import { shipmentSocketActions } from '../../slices/shipmentSocketSlice'
import ROUTES from '../../constants/routes'
import { CopyText } from '../globals'

export default function PaymentDialogue ({
  orderData,
  customer,
  onSuccess,
  setOrderDetails = () => {},
  onProcessOrder = () => {},
  onShipment = () => {},
  multipiece
}) {
  const providerOptions = [
    {
      payment_method: 'TRF',
      merchant: 'Opay Bank - Transfer',
      icon: opay,
      text: 'Opay Bank - Pay with Transfer',
      provider: 'OPY',
      disabled: false
    },
    {
      payment_method: 'POS',
      merchant: 'Opay Bank - POS',
      icon: opay,
      text: 'Opay Bank - Pay with POS',
      provider: 'OPY',
      disabled: false
    },
    {
      payment_method: 'CASH',
      merchant: 'Cash Payment',
      icon: cash,
      text: 'Pay With Cash',
      provider: '',
      disabled: !!multipiece
    }
  ]

  const paymentSocket = useSelector(state => state.paymentSocket)
  const shipmentSocket = useSelector(state => state.shipmentSocket)
  const transaction = useSelector(state => state.transactions)

  const [method, setMethod] = useState(null)
  const [showCopied, setShowCopied] = useState(false)
  const [status, setStatus] = useState('select provider')
  const [error, setError] = useState()
  const [transactionData, setTransactionData] = useState(null)
  const [providerData, setProviderData] = useState()
  const [timer, setTimer] = useState('')
  const [payment, setPayment] = useState(
    multipiece
      ? orderData.map(state => ({ status: 'listening', data: null }))
      : { status: 'listening', data: null }
  )
  const [isConfirming, setConfirming] = useState(false)

  const [payLater, setPayLater] = useState(false)

  const location = useLocation()

  const isInvoicePayment = location.pathname.includes(ROUTES.INVOICES.path)

  const toast = useToast()
  const timerInterval = useRef(null)

  const dispatch = useDispatch()

  const isPaylaterEnabled = useMemo(() => {
    return !isInvoicePayment && customer.pay_later_eligible
  }, [customer.pay_later_eligible, isInvoicePayment])

  useEffect(() => {
    if (
      paymentSocket.status === 'disconnected' &&
      paymentSocket.trialCount < 6
    ) {
      setTimeout(() => {
        dispatch(paymentSocketActions.connect())
      }, 1000)
    }
    if (
      shipmentSocket.status === 'disconnected' &&
      shipmentSocket.trialCount < 6
    ) {
      setTimeout(() => {
        dispatch(shipmentSocketActions.connect())
      }, 1000)
    }
  }, [dispatch, paymentSocket, shipmentSocket])

  useEffect(() => {
    setTimeout(() => {
      setShowCopied(false)
    }, 3000)
  }, [showCopied])

  const updateOrderDetails = data => {
    const isMultipiece = Array.isArray(data)
    if (isMultipiece) {
      setOrderDetails(state => {
        const newState = state.map((details, id) => ({
          ...details,
          ...data[id]
        }))
        return newState
      })
    } else {
      setOrderDetails(state => ({
        ...state,
        ...data
      }))
    }
  }

  useEffect(() => {
    if (transaction.initiate) {
      setTransactionData(transaction.initiate)
      if (transaction.initiate.pay_later) {
        !payLater && setPayLater(true)
        onProcessOrder()
        setStatus('select provider')
      } else if (transaction.initiate.payment_method === 'POS') {
        setStatus('listening')
        setProviderData(transaction.initiate.provider_data)
      } else if (transaction.initiate.payment_method === 'TRF') {
        setStatus('listening')
        setProviderData(transaction.initiate.provider_data.data)
      } else {
        // cash
        setStatus('listening')
      }
      dispatch(transactionActions.cleanup())
    }
    if (transaction.initiate_error) {
      setStatus('error')
      setError(transaction.initiate_error)
      dispatch(transactionActions.cleanup())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction.initiate, transaction.initiate_error])

  useEffect(() => {
    if (transaction.confirm_payment) {
      setConfirming(false)
      toast(
        `Payment of ${transactionData.amount.total.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        })} for order ID #${transactionData.order} received!`
      )
      setPayment({
        status: 'received',
        data: {
          transaction: transactionData
        }
      })
      updateOrderDetails({
        receipt: transaction.confirm_payment.receipt
      })
      setStatus('received')
      dispatch(transactionActions.cleanup())
    }
    if (transaction.confirm_payment_error) {
      setConfirming(false)
      toast(transaction.confirm_payment_error, 'error')
      dispatch(transactionActions.cleanup())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction.confirm_payment, transaction.confirm_payment_error])

  useEffect(() => {
    if (providerData) {
      const timeStamp = Math.round((Date.now() + 1800000) / 1000) * 1000 // 30 mins
      timerInterval.current = setInterval(() => {
        const now = Date.now()
        const expiryTime =
          method.payment_method === 'POS'
            ? timeStamp
            : providerData.nextAction.expiredTimestamp * 1000
        if (!(expiryTime > now)) {
          setTimer('expired')
        } else {
          const durationInMilis = expiryTime - now
          const durationInSecs = Math.floor(durationInMilis / 1000)
          const minute = Math.floor(durationInSecs / 60)
          const second = durationInSecs % 60
          const formattedMinute =
            String(minute).length < 2 ? `0${minute}` : `${minute}`
          const formattedSecond =
            String(second).length < 2 ? `0${second}` : `${second}`
          setTimer(`${formattedMinute}:${formattedSecond}`)
        }
      }, 1000)
    } else {
      setTimer('')
    }

    return () => {
      clearInterval(timerInterval.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerData])

  const onPaymentSuccess = () => {
    setStatus('received')
    setTimer('')
    clearInterval(timerInterval.current)
  }
  const onPaymentFail = () => {
    toast('Payment failed', 'error')
    setStatus('select provider')
    setProviderData(null)
  }
  const onPaymentClose = () => {
    toast('Payment not received or invalid', 'error')
    setStatus('select provider')
    setProviderData(null)
  }

  useEffect(() => {
    const handlePaymentMessage = message => {
      const { data } = message
      const isMultipiece = Array.isArray(data)
      let paymentData, orderDetails, status

      if (isMultipiece) {
        paymentData = []
        orderDetails = []
        status = data[0].status

        data.forEach(item => {
          if (item.status === 'SUCCESS') {
            paymentData.push({
              status: 'received',
              data: { transaction: transactionData }
            })
            orderDetails.push({ receipt: item.receipt })
          }
        })
      } else {
        status = data.status
        paymentData = {
          status: 'received',
          data: { transaction: transactionData }
        }
        orderDetails = { receipt: data.receipt }
      }

      switch (status) {
        case 'SUCCESS':
          setPayment(paymentData)
          updateOrderDetails(orderDetails)
          onPaymentSuccess()
          break
        case 'FAILED':
          onPaymentFail()
          break
        case 'CLOSE':
          onPaymentClose()
          break
        default:
          // Handle unexpected status
          break
      }
    }

    if (paymentSocket.message) {
      const { message } = paymentSocket

      handlePaymentMessage(message)
      dispatch(paymentSocketActions.cleanup())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSocket.message, transactionData])

  useEffect(() => {
    const handleShipmentMessage = message => {
      const { data } = message
      const isMultiPiece = Array.isArray(data)

      if (isMultiPiece) {
        const orderDetails = []
        const shipment = []

        data.forEach((piece, id) => {
          const { meta, status, shipment: pieceShipment } = piece
          const isMatchingReference =
            meta.referenceNumber === transactionData.reference_number

          if (isMatchingReference) {
            if (status === 'SUCCESS') {
              orderDetails.push({
                shipment: pieceShipment,
                tpl_service: orderData[id].order.tpl_service
              })
              shipment.push({ status: 'processed' })
            } else if (status === 'FAILED') {
              const error =
                'An error occurred processing shipment, please try again later.'
              shipment.push({ status: 'error', error })
            }
          }
        })

        setTimeout(() => {
          updateOrderDetails(orderDetails)
          onShipment(shipment)
        }, 2000)
      } else {
        const { meta, status, shipment: singleShipment } = data
        const isMatchingReference =
          meta.referenceNumber === transactionData.reference_number

        if (isMatchingReference) {
          const orderDetails = {
            shipment: singleShipment,
            tpl_service: orderData.order.tpl_service
          }
          const shipment = {
            status: status === 'SUCCESS' ? 'processed' : 'error'
          }
          const errorMessage =
            'An error occurred processing shipment, please try again later.'

          setTimeout(() => {
            if (status === 'SUCCESS') {
              updateOrderDetails(orderDetails)
              onShipment(shipment)
            } else {
              onShipment({ status: 'error', error: errorMessage })
            }
          }, 2000)
        }
      }
    }

    if (shipmentSocket.message) {
      const { message } = shipmentSocket

      handleShipmentMessage(message)
      dispatch(shipmentSocketActions.cleanup())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentSocket.message, transactionData, orderData])

  useEffect(() => {
    if (timer === 'expired') {
      toast('Account number validity expired!', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer])

  useEffect(() => {
    let _payment = Array.isArray(payment) ? payment[0] : payment

    if (_payment.status === 'received') {
      onSuccess()
      if (
        _payment.data.transaction.payment_method === 'CASH' &&
        !isInvoicePayment
      ) {
        setTimeout(onProcessOrder, 2000)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment])

  const onSelectMethod = method => {
    setMethod(method)
    onInitiateTransaction(method)
  }

  const onInitiateTransaction = method => {
    const payload = {
      customer_name: customer.contact.name,
      description: multipiece
        ? orderData[0].order.description
        : orderData.order.description,
      name: `Payment for ${
        multipiece ? `multipiece order` : `order ${orderData.order.id}`
      }`,
      phone_number: customer.contact.phone_number.replace('+', ''),
      bill_to: multipiece
        ? orderData[0].order[multipiece.bill_to].customer_id
        : orderData.quote.bill_to
    }
    if (multipiece) {
      payload.multi_piece = true
      payload.multi_piece_order = multipiece.id
    } else {
      payload.order = orderData.order.id
    }
    if (method === 'pay_later') {
      setPayLater(true)
      payload.pay_later = true
    }
    if (method.payment_method === 'CASH') {
      payload.payment_method = method.payment_method
    } else {
      payload.provider = method.provider
      payload.payment_method = method.payment_method
    }

    setStatus('initiating')
    dispatch(initiateTransaction(payload))
  }

  const onRetry = () => {
    setStatus('select provider')
    setTransactionData(null)
    setProviderData(null)
    setMethod(null)
    setTimer('')
  }

  const handleConfirmPayment = () => {
    if (!isConfirming) {
      setConfirming(true)
      dispatch(confirmCashPayment(transactionData.reference_number))
    }
  }

  return (
    <>
      {(multipiece
        ? payment[0].status === 'received'
        : payment.status === 'received') && (
        <PaymentSuccessful
          isOpen={
            multipiece
              ? payment[0].status === 'received'
              : payment.status === 'received'
          }
          transaction={
            multipiece ? payment[0].data.transaction : payment.data.transaction
          }
        />
      )}
      <div className='w-full md:max-w-4xl bg-white rounded-lg flex min-h-[400px] md:min-h-[500px] shadow-lg text-center'>
        {paymentSocket.status === 'connected' &&
        shipmentSocket.status === 'connected' ? (
          <>
            <div
              className={`bg-white md:bg-[#F9F8F8] w-full md:max-w-[320px] rounded-l-lg rounded-r-lg md:rounded-r-none py-8 ${
                method ? 'hidden md:block' : 'block md:block'
              }`}
            >
              <div className='relative h-full'>
                <div className='px-8 pb-8'>
                  <h4 className='text-lg font-semibold'>
                    Select a payment method
                  </h4>
                </div>
                <div className='pt-6'>
                  <ul className='divide-y divide-[#C9C6C6]'>
                    {providerOptions.map((item, id) => (
                      <li key={id}>
                        <button
                          className={`px-4 py-6 transition-all w-full disabled:opacity-50 disabled:cursor-not-allowed ${
                            method?.merchant === item.merchant
                              ? 'bg-[#FF4D00] text-white'
                              : 'hover:bg-[#F6F4F4]'
                          }`}
                          onClick={() => onSelectMethod(item)}
                          disabled={item.disabled || !!method}
                        >
                          {item.text}
                        </button>
                      </li>
                    ))}
                  </ul>
                  {isPaylaterEnabled && (
                    <div className='md:hidden w-full flex flex-col items-center mt-4'>
                      <div className='mb-6'>Or</div>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => onSelectMethod('pay_later')}
                      >
                        Pay Later
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`bg-white flex-col items-center justify-center p-6 w-full rounded-lg md:rounded-l-none ${
                method ? 'flex' : 'hidden md:flex'
              }`}
            >
              <div
                className={`p-6 bg-[#F9F8F8] w-full max-w-md min-h-[300px] rounded-lg`}
              >
                {status === 'initiating' && (
                  <div className='flex flex-col gap-4 items-center justify-center h-full w-full'>
                    <p>
                      {payLater ? 'Initiating...' : 'Initiating transaction'}
                    </p>
                    <span>
                      <CircularProgress
                        size={28}
                        style={{ color: '#FF4D00' }}
                      />
                    </span>
                  </div>
                )}
                {(status === 'listening' || status === 'received') && (
                  <div className='flex flex-col items-center justify-center gap-4'>
                    <p>
                      {method?.payment_method === 'POS'
                        ? 'Pay'
                        : method?.payment_method === 'TRF'
                        ? 'Transfer'
                        : 'CASH'}
                      <strong>
                        <span className='ml-1'>
                          {(
                            multipiece?.total || orderData.quote.total
                          ).toLocaleString('en-NG', {
                            style: 'currency',
                            currency: 'NGN'
                          })}
                        </span>
                      </strong>
                    </p>
                    <div className='bg-[#F3F3F3] w-[80%] p-6 py-8 flex flex-col items-center justify-center gap-5'>
                      <div className='flex w-full justify-center flex-col md:flex-row gap-2 md:gap-3 items-center'>
                        {method?.icon && (
                          <img
                            src={method.icon}
                            alt=''
                            width={24}
                            height={24}
                            className='object-contain'
                          />
                        )}
                        <span>{method?.merchant}</span>
                      </div>
                      <div className='flex items-center gap-2.5'>
                        {method.payment_method === 'CASH' ? (
                          <button
                            className='btn btn-primary text-white min-w-32 flex items-center justify-center'
                            onClick={handleConfirmPayment}
                          >
                            {isConfirming ? (
                              <CircularProgress
                                size={28}
                                style={{ color: '#FFFFFF' }}
                              />
                            ) : (
                              'Confirm Payment'
                            )}
                          </button>
                        ) : (
                          <span className='text-2xl font-semibold'>
                            {providerData.nextAction?.transferAccountNumber ||
                              'Via POS'}
                          </span>
                        )}
                        {providerData?.nextAction?.transferAccountNumber && (
                          <CopyText
                            text={
                              providerData?.nextAction?.transferAccountNumber
                            }
                          />
                        )}
                      </div>
                      <div>
                        <p
                          className={`text-sm ${
                            timer === 'expired'
                              ? 'text-error'
                              : 'text-[#3A3A3A99]'
                          } `}
                        >
                          {timer ? (
                            timer !== 'expired' ? (
                              <>
                                Expires in{' '}
                                <span className='font-medium'>{timer}</span>{' '}
                                mins
                              </>
                            ) : (
                              'Expired!!!'
                            )
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </div>

                    {status === 'listening' && timer !== 'expired' && (
                      <div className='flex items-center gap-3 text-primary text-sm'>
                        Waiting{' '}
                        {method.payment_method === 'CASH'
                          ? 'to confirm payment'
                          : 'for payment'}
                        <CircularProgress
                          size={16}
                          style={{ color: '#FF4D00' }}
                        />
                      </div>
                    )}
                    {timer === 'expired' && (
                      <div className='flex items-center'>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={onRetry}
                        >
                          Retry
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {status === 'select provider' && (
                  <div className='flex flex-col items-center justify-center h-full w-full relative'>
                    <h4 className='text-lg font-semibold'>
                      Select a payment method
                    </h4>
                    {isPaylaterEnabled && (
                      <div className='flex flex-col items-center absolute bottom-0'>
                        <div className='mb-6'>Or</div>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => onSelectMethod('pay_later')}
                        >
                          Pay Later
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {status === 'error' && (
                  <div className='flex items-center justify-center h-full w-full relative'>
                    <p className='text-error'>Error: {error}</p>

                    <div className='flex flex-col items-center absolute bottom-0'>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={onRetry}
                      >
                        Retry
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <Loader />
          </div>
        )}
      </div>
    </>
  )
}
