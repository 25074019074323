import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Transition
} from '@headlessui/react'
import { useContext, useMemo, useState } from 'react'
import { statuses } from '../../fixtures/shipmentsStatus'
import { MdCheck } from 'react-icons/md'
import { Fragment } from 'react'
import { RxCaretDown } from 'react-icons/rx'
import shipmentsApi from '../../api/shipments'
import { parseError } from '../../utils'
import useToast from '../../hooks/useToast'
import { CircularProgress } from '@mui/material'
import Pill from '../globals/Pill'
import ROLES from '../../constants/roles'
import { AuthLayoutContext } from '../../containers/AuthLayout'

const statusOptions = Object.entries(statuses).map(([id, props]) => ({
  id,
  name: props.name,
  theme: props.theme,
  disabled: !props.allowUpdate
}))

export default function ShipmentStatusUpdate ({
  trackingId,
  statusId,
  onSuccess,
  deliveryMode
}) {
  const [selectedStatus, setSelectedStatus] = useState(
    statusOptions.find(({ id }) => Number(id) === statusId)
  )
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const { userRole } = useContext(AuthLayoutContext)

  const handleChange = async status => {
    if (
      status.id === 6 &&
      (deliveryMode === 'DROP_OFF' ||
        deliveryMode === 'SIGNATURE' ||
        deliveryMode === 'DOOR_STEP')
    ) {
      toast(
        `Shipment status - ${status.name} - cannot be assigned for delivery mode - ${deliveryMode}`,
        'error'
      )
    }

    if (status.id === 3 && deliveryMode === 'PICKUP') {
      toast(
        `Shipment status - ${status.name} - cannot be assigned for delivery mode - ${deliveryMode}`,
        'error'
      )
      return
    }

    setSelectedStatus(status)
    setLoading(true)

    const response = await shipmentsApi.updateShipmentStatus(trackingId, {
      move_to: `${status.id}`,
      exception: {}
    })

    setLoading(false)

    if (!response.ok) {
      setSelectedStatus(statusOptions.find(({ id }) => Number(id) === statusId))

      const apiError = parseError(response)
      if (apiError) {
        if (Array.isArray(apiError.data?.detail)) {
          toast(apiError.data.detail[0]?.message, 'error')
        } else {
          toast('Error updating shipment status', 'error')
        }
      }

      return
    }

    onSuccess()

    toast('Shipment status updated', 'success')
  }

  const canUpdateStatus = useMemo(() => {
    if (userRole) {
      return ROLES[userRole.id].permissions.shipments?.update_status
    }
  }, [userRole])

  return !canUpdateStatus ? (
    <Pill name={selectedStatus.name} theme={selectedStatus.theme} />
  ) : (
    <Listbox
      value={selectedStatus}
      onChange={handleChange}
      as='div'
      className='relative'
    >
      <div className='flex items-center gap-2'>
        <ListboxButton className='flex items-center justify-end gap-2 bg-white hover:bg-g-100 border border-g-400 p-1 focus:outline-0 rounded-lg'>
          <Pill name={selectedStatus.name} theme={selectedStatus.theme} />
          <RxCaretDown size={18} />
        </ListboxButton>
        {loading && (
          <span>
            <CircularProgress size={16} style={{ color: '#FF4D00' }} />
          </span>
        )}
      </div>
      <Transition
        as={Fragment}
        leave='transition ease-out duration-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <ListboxOptions className='absolute right-0 mt-1 overflow-y-auto overflow-x-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-0 w-56'>
          <div className='text-sm font-medium border-b p-2'>
            <h4>Update status</h4>
          </div>
          <div className='py-2 space-y-2'>
            {statusOptions.map(status => (
              <ListboxOption
                key={status.id}
                value={status}
                className='group px-2 data-[disabled]:opacity-50 data-[disabled]:cursor-not-allowed'
                disabled={status.disabled}
              >
                {({ selected }) => {
                  return (
                    <div className='relative select-none cursor-pointer w-48 flex px-2 items-center rounded-[4px] bg-white group-data-[focus]:bg-g-100'>
                      <div
                        className={`p-[1px] w-4 h-4 border border-[${status.theme.primary}] rounded-full bg-transparent overflow-hidden`}
                      >
                        <div
                          className={`w-full h-full rounded-full`}
                          style={{ backgroundColor: status.theme.primary }}
                        ></div>
                      </div>

                      <div className='py-0.5 pl-3 pr-2 text-[13px] btn-rounded select-none font-normal data-[selected]:font-medium'>
                        {status.name}
                      </div>
                      {selected && (
                        <span className='pointer-events-none ml-auto flex items-center'>
                          <MdCheck size={14} color='#fe6802' />
                        </span>
                      )}
                    </div>
                  )
                }}
              </ListboxOption>
            ))}
          </div>
        </ListboxOptions>
      </Transition>
    </Listbox>
  )
}
