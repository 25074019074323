import { MdOutlineFileDownload } from 'react-icons/md'
import { ShipmentsFilter, Table } from '../../components'
import Search from '../../components/globals/Search/Search'
import Page from '../../containers/Page'
import Tabs from '../../components/globals/Tabs'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import FilterTag from '../../components/globals/filter/FilterTag'
import { useDispatch, useSelector } from 'react-redux'
import {
  exportCSVData,
  getDate,
  getStateOfCountry,
  initializeDateRange,
  parseError
} from '../../utils'
import Pagination from '../../components/globals/pagination/ServerPagination'
import ShipmentDetail from '../../components/shipments/ShipmentDetail'
import { fetchShipments } from '../../slices/shipmentsSlice'
import { fetchBranches } from '../../slices/orgsSlice'
import { Link, useSearchParams } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import { resolveDateParams } from '../../helpers/queryByDate'
import { statuses } from '../../fixtures/shipmentsStatus'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import Pill from '../../components/globals/Pill'
import { CircularProgress } from '@mui/material'
import shipmentsApi from '../../api/shipments'
import useToast from '../../hooks/useToast'
import ExportDialogue from '../../components/globals/export/ExportDialogue'
import Filter from '../../components/globals/filter/Filter'
import { ExceptionIcon, AssignUserIcon } from '../../components/icons'
import AssignShipmentToDriver from '../../components/shipments/AssignShipmentToDriver'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import aajLogo from '../../assets/logo.png'
import aspLogo from '../../assets/assist-dispatch.png'
import { LiaTimesSolid } from 'react-icons/lia'
import ShipmentException from '../../components/shipments/ShipmentException'

const tabs = [
  {
    name: 'International',
    value: 'IN'
  },
  {
    name: 'Local',
    value: 'LC'
  }
]

export default function Shipments ({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext)
  const [searchParams] = useSearchParams()
  const statusQuery = searchParams.get('status') ?? ''
  const userData = useSelector(state => state.auth.user)
  const shipmentsStore = useSelector(state => state.shipments)
  const [activeTab, setActiveTab] = useState(tabs[0])

  const searchOptions = useMemo(() => {
    const options = [
      { name: 'Tracking ID', value: 'tracking_id' },
      { name: 'Order ID', value: 'order' },
      {
        name: "Receiver's name",
        value: 'receiver_name'
      }
    ]

    if (activeTab.value === 'IN') {
      options.splice(1, 0, {
        name: 'Carrier Tracking No',
        value: 'shipment_tracking_number'
      })
    }

    return options
  }, [activeTab])

  const [isRaiseExceptionModal, setRaiseExceptionModal] = useState(false)
  const [searchBy, setSearchBy] = useState(searchOptions[0].value)
  const [searchValue, setSearchValue] = useState('')
  const [serverSearch, setServerSearch] = useState(null)
  const [serializedData, setSerializedData] = useState(null)
  const [filterTags, setFilterTags] = useState([])
  const [activeShipment, setActiveShipment] = useState(null)
  const [isExportOpen, setExportOpen] = useState(false)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50,
    order_type: activeTab.value,
    status: statusQuery,
    tracking_id: '',
    shipment_tracking_number: '',
    branch: ''
  })
  const [filter, setFilter] = useState({
    package_type: '',
    category: '',
    date: '',
    branch: '',
    status: statusQuery,
    shipment_type: ''
  })
  const [isExportLoading, setExportLoading] = useState(false)
  const [dateRange, setDateRange] = useState(initializeDateRange())
  const [isShipmentModal, setShipmentModal] = useState(false)
  const [isAssignDriverModal, setAssignDriverModal] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()

  const onSearchChange = e => {
    setSearchValue(e.target.value)
  }

  const loadShipments = useCallback(() => {
    const promise = dispatch(fetchShipments(queryParams))

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (userRole?.domain.index > 0) {
      dispatch(fetchBranches())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole?.domain.index])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = filter[key].replaceAll('_', ' ')
          }
          tags.push(tag)
        } else if (key === 'status') {
          params.status = filter[key]
          let tag = { name: key, value: statuses[filter[key]]?.name }
          tags.push(tag)
        } else if (key === 'package_type') {
          let tag = { name: key, value: '' }
          const packages = {
            regular: 'Regular',
            document: 'Document',
            'fish/snail': 'Fish'
          }
          params.package_type = filter[key]
          tag.value = packages[filter[key]]
          tags.push(tag)
        } else if (key === 'category') {
          let tag = { name: key, value: '' }
          const categories = {
            1: 'Electronics',
            2: 'Non-Eletronics',
            3: 'Haulage'
          }
          tag.value = categories[filter[key]]
          params.category = filter[key]
          tags.push(tag)
        } else if (key === 'branch') {
          let tag = { name: key, value: filter[key] }

          tags.push(tag)
          params.branch = filter[key]
        } else if (key === 'shipment_type') {
          let tag = { name: 'Carrier', value: filter[key] }
          tags.push(tag)
          params.shipment_type = filter[key]
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadShipments()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadShipments])

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
    let query = { ...queryParams }
    if (key === 'date') {
      delete query.start_date
      if (filter[key] !== 'today') delete query.end_date
      if (filter[key] === 'range') setDateRange(initializeDateRange())
    } else delete query[key]

    setQueryParams(query)
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const onSelectTab = item => {
    setQueryParams(state => ({ ...state, order_type: item.value }))
    setActiveTab(item)
  }

  const searchInputPlacehHolder = useMemo(() => {
    return searchBy === 'tracking_id'
      ? 'Enter tracking ID'
      : searchBy === 'shipment_tracking_number'
      ? 'Enter Carrier tracking ID'
      : searchBy === 'receiver_name'
      ? "Enter receiver's name"
      : 'Enter order ID'
  }, [searchBy])

  const shipments = useMemo(() => {
    if (serializedData) {
      if (searchValue) {
        const results = serializedData.filter(shipment => {
          const queries = {
            search: {
              apply: !!searchValue,
              match () {
                // eslint-disable-next-line default-case
                switch (searchBy) {
                  case 'tracking_id':
                    return shipment.tracking_id
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  case 'shipment_tracking_number':
                    return shipment.shipment_tracking_number
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  case 'receiver_name':
                    return shipment.meta.receiver_name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  case 'order':
                    return shipment.order
                      .toString()
                      .includes(searchValue.toLowerCase().replace('#', ''))
                }
              }
            }
          }

          let matchesQueries = []

          for (let query in queries) {
            if (queries[query].apply) {
              matchesQueries.push(queries[query].match())
            }
          }

          return matchesQueries.every(match => match)
        })
        return results
      }
      return serializedData
    } else return null
  }, [searchBy, searchValue, serializedData])

  const onActiveShipment = row => {
    setActiveShipment(row)
    setShipmentModal(true)
  }

  const handleShipmentModalClose = () => {
    setShipmentModal(false)
    setActiveShipment(null)
  }

  const handleOnVoidedShipment = () => {
    loadShipments()
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === searchBy)?.name,
      searchValue
    })
    setSearchValue('')
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[searchBy]
    setQueryParams(query)
  }

  const exportShipments = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await shipmentsApi.exportShipments(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `shipments_${userData.branch.name}.csv`
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return 'shipments.csv'
      }
    })()

    exportCSVData(response.data, file_name)

    onCompleted()
  }

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportShipments(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const closeExport = () => {
    setExportOpen(false)
  }

  const handleAssignDriveModalClose = () => {
    setAssignDriverModal(false)
    setActiveShipment(null)
  }

  const handleExceptionModalClose = () => {
    setRaiseExceptionModal(false)
    setActiveShipment(null)
  }

  const tableHeader = useMemo(() => {
    return [
      'S/N',
      'Tracking ID',
      'Order',
      'Date',
      'Origin',
      'Destination',
      'Receiver',
      `Delivery ${activeTab.value === 'LC' ? 'Mode' : 'Type'}`,
      'Carrier',
      'Status'
    ]
  }, [activeTab.value])

  const tableData = useMemo(() => {
    return shipments?.map(shipment => ({
      ...shipment,
      'S/N': shipment.s_n,
      'Tracking ID': shipment.tracking_id,
      Order: (
        <Link
          to={`${ROUTES.ORDERS.path}?id=${shipment.order}`}
          onClick={e => e.stopPropagation()}
          className='hover:text-primary hover:underline'
        >
          #{shipment.order}
        </Link>
      ),
      Date: getDate(shipment.created_at),
      Origin: `${
        getStateOfCountry(shipment.meta.origin[1], shipment.meta.origin[0])
          ?.name || shipment.meta.origin[1]
      }, ${shipment.meta?.origin[0]}`,
      Destination: `${
        getStateOfCountry(
          shipment.meta.destination[1],
          shipment.meta.destination[0]
        )?.name || shipment.meta.destination[1]
      }, ${shipment.meta?.destination[0]}`,
      Receiver: shipment.meta?.receiver_name,
      ...(activeTab.value === 'LC'
        ? {
            'Delivery Mode': shipment.meta.delivery_mode?.replace('_', ' ')
          }
        : {
            'Delivery Type': shipment.meta.delivery_type?.replace('_', ' ')
          }),
      Carrier:
        shipment.meta.tpl_service === 'FIE' ||
        shipment.meta.tpl_service === 'FPE'
          ? 'FEDEX'
          : shipment.meta.tpl_service,
      Status: (
        <Pill
          name={statuses[shipment.status]?.name}
          theme={statuses[shipment.status]?.theme}
        />
      )
    }))
  }, [activeTab.value, shipments])

  const rowMenuItems = row => [
    {
      name: 'Raise exception',
      icon: ExceptionIcon,
      action: row => {
        setActiveShipment(row)
        setRaiseExceptionModal(true)
      },
      disabled: row.status === 4 || row.status === 7
    },
    {
      name: (
        <AssignToRidersMenu
          setActiveShipment={setActiveShipment}
          setAssignDriverModal={setAssignDriverModal}
        />
      ),
      icon: AssignUserIcon,
      action: row => {
        setActiveShipment(row)
      },
      disabled: row.status === 0 || row.status === 4 || row.status === 7
    }
  ]

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Shipments'}>
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={option => setSearchBy(option)}
          inputPlaceHolder={searchInputPlacehHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={ShipmentsFilter}
          filter={filter}
          setFilter={setFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
          type={activeTab.value}
        />
      </Page.Header>
      <Page.Body>
        {isShipmentModal && activeShipment && (
          <ShipmentDetail
            id={activeShipment.id}
            isOpen={isShipmentModal}
            onClose={handleShipmentModalClose}
            onVoided={handleOnVoidedShipment}
            onUpdateShipments={loadShipments}
            type={activeTab.value}
          />
        )}
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='shipments'
            onClose={closeExport}
            options={queryParams}
            onExport={exportShipments}
          />
        )}
        {isRaiseExceptionModal && activeShipment && (
          <ShipmentException
            isOpen={isRaiseExceptionModal}
            onClose={handleExceptionModalClose}
            shipmentId={activeShipment.tracking_id}
          />
        )}
        {isAssignDriverModal && activeShipment && (
          <AssignShipmentToDriver
            isOpen={isAssignDriverModal}
            onClose={handleAssignDriveModalClose}
            shipment={{
              id: activeShipment.id,
              tracking_id: activeShipment.tracking_id
            }}
          />
        )}
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}

            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-base font-medium text-dark-primary'>
                  Filter:
                </p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='shipments-table'
                pageSize={shipmentsStore.meta?.page_size}
                totalCount={shipmentsStore.meta?.count}
                data={shipmentsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={shipmentsStore.meta?.page}
              />
            </div>
          </div>

          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <Tabs items={tabs} onSelectTab={onSelectTab} active={activeTab} />
            <div className='flex items-center gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='shipments-table'
                  pageSize={shipmentsStore.meta?.page_size}
                  totalCount={shipmentsStore.meta?.count}
                  data={shipmentsStore.data}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={shipmentsStore.meta?.page}
                />
              </div>
              <button
                className='btn bg-g-400 text-dark-primary'
                onClick={handleExport}
                disabled={!shipments?.length}
              >
                <MdOutlineFileDownload
                  size={18}
                  color='#333333'
                  className='mr-1'
                />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress
                      style={{ marginLeft: '0.5rem' }}
                      size={18}
                      color='inherit'
                    />
                  </>
                ) : (
                  'Export'
                )}
              </button>
            </div>
          </div>
        </div>
        <Table
          id='shipments-table'
          headers={tableHeader}
          data={tableData}
          rowAction={onActiveShipment}
          emptyDataText='No shipment found'
          withMenu
          rowMenuItems={rowMenuItems}
        />
      </Page.Body>
    </Page>
  )
}

const AssignToRidersMenu = ({ setActiveShipment, setAssignDriverModal }) => {
  const items = [
    {
      name: 'AAJ Astros',
      icon: aajLogo,
      img_alt: 'AAJ',
      disabled: false,
      action: () => {
        setAssignDriverModal(true)
      }
    },
    {
      name: 'Assist Dispatch',
      icon: aspLogo,
      img_alt: 'ASP',
      disabled: true,
      action: () => {}
    }
  ]

  return (
    <Menu>
      {({ close }) => (
        <>
          <MenuButton className='data-[open]:text-main-primary-2'>
            Assign to Astro
          </MenuButton>

          <MenuItems
            transition
            anchor='bottom end'
            className='w-52 origin-top-right bg-white rounded-md max-h-80 shadow-lg ring-1 ring-black/5 text-sm overflow-x-hiden overflow-y-auto transition duration-00 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 py-4'
            as='ul'
          >
            <div className='px-3'>
              <button
                className='w-5 h-5 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
                onClick={close}
              >
                <LiaTimesSolid size={16} />
              </button>
              <p className='text-base py-2 font-medium'>Assign Shipment To</p>
            </div>
            {items.map((item, id) => {
              return (
                <MenuItem
                  key={id}
                  as='li'
                  className='group'
                  disabled={!!item.disabled}
                >
                  <div
                    className={twMerge(
                      'flex flex-row w-full items-center gap-2 py-2 px-3 rounded-none group-data-[disabled]:opacity-50 group-data-[disabled]:cursor-not-allowed',
                      !item.disabled &&
                        'hover:bg-main-hover hover:text-main-primary-2'
                    )}
                    role='button'
                    onClick={item.action}
                  >
                    <span className='w-6 h-6 flex items-center justify-center rounded-full bg-g-200'>
                      <img
                        src={item.icon}
                        className='object-contain'
                        width={item.img_alt === 'ASP' ? 24 : 18}
                        height={item.img_alt === 'ASP' ? 24 : 18}
                        alt={item.img_alt}
                      />
                    </span>
                    <span className='w-[calc(100%-1.5rem)] flex p-0 truncate'>
                      {item.name}
                    </span>
                  </div>
                </MenuItem>
              )
            })}
          </MenuItems>
        </>
      )}
    </Menu>
  )
}
